import "animate.css/animate.min.css";
import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "../style/Body.css";
import images from "../api/images";
import Contactus from "./Contactus";
import { Link} from "react-router-dom";
const Body = () => {
  function openTab(){
    window.open("https://ragnartoken.com/buytoken");
  }
  return (
    <>
      <div className="ideology">
        <div className="container padd-top-150 padd-bot-150" id="about">
          <div className="row">
            <div className="col-md-7">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
                delay={1}
              >
                <h1 className="left-white-head1">What is Ragnar Token? </h1>
                <div className="divider1 marg-top-20"></div>
                <p className="left-white-para1 marg-top-20 padd-right-50">
                  RAG is a custom built new cryptocurrency that is intended to
                  raise capital for RTL as well as provide token holders with a
                  percentage ownership of RTL.
                </p>
                <p className="grey-sec-head mt-5">Key facts</p>
                <ul>
                  <li className="left-white-para1 marg-top-20">
                    There are a total of 50,000 tokens in supply.
                  </li>
                  <li className="left-white-para1 marg-top-20">
                    50,000 RAG equates to a maximum of 5% ownership of RTL.
                  </li>
                  <li className="left-white-para1 marg-top-20">
                    RAG tokens are built on Binance Smart Chain (BSC).
                  </li>
                  <li className="left-white-para1 marg-top-20">
                    Built using BEP 20 standards.
                  </li>
                  <li className="left-white-para1 marg-top-20">
                    Token holders will also receive free one year of premium
                    banking services.
                  </li>
                   <br></br>
                  <Link
                    to="/buytoken"
                    className="nav-link"
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      backgroundColor: "black",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      padding: "16px",
                      
                    }}
                  >
                    
                      Buy Tokens
              
                  </Link>
                </ul>
              </AnimationOnScroll>
            </div>

            <div className="col-md-5">
              <AnimationOnScroll
                animateIn="animate__fadeInRightBig"
                animateOnce={true}
                delay={1}
              >
                <img
                  className="tri-cent"
                  src={images.blockchain}
                  width="100%"
                  alt=""
                />
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
      <div className="container marg-top-150" id="goals">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <AnimationOnScroll
              animateIn="animate__fadeInLeftBig"
              animateOnce={true}
              delay={1}
            >
              <h1 className="center-white-head">Goals and plans</h1>
            </AnimationOnScroll>
            <div className="divider-center marg-top-30"></div>
            <AnimationOnScroll
              animateIn="animate__fadeInRightBig"
              animateOnce={true}
              delay={1}
            >
              <p className="centrer-white-para marg-top-20">
                To establish RTL, we have a key set of goals we need that we
                will fulfil- we refer to these at our three C’s:
              </p>
            </AnimationOnScroll>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
      {/* Benifits */}
      <section className="ben-for-desk">
        <div className="container marg-top-50">
          <div className="row">
            <div className="col-md-4">
              <div className="icon-desc-set marg-top-10">
                <img src={images.firstcol} width="20%" />
                <h3 className="ben-head mt-4">Capital</h3>
                <p className="grey-sec-para marg-top-10 ">
                  This is required for trading and regulatory purposes. We
                  intend to raise capital through the distribution of RAG. This
                  will enable us to deliver a key part of our service which is
                  to lend funding to a range of different industries.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="icon-desc-set marg-top-10 ">
                <img src={images.secondben} width="20%" />
                <h3 className="ben-head mt-4">Compliance</h3>
                <p className="grey-sec-para marg-top-10">
                  Access to and application of the relevant licencing is
                  essential. We intend to apply for and gain access to the UK’s
                  Financial Conduct Authority. This is will be our first step to
                  developing a global presence. Post-UK licencing, we will look
                  to expand to the European economic area.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="icon-desc-set marg-top-10 ">
                <img src={images.thirdben} alt="" width="20%" />
                <h3 className="ben-head mt-4">Cutting-edge technology</h3>
                <p className="grey-sec-para  marg-top-10">
                  RTL will incorporate the use of blockchain and leading
                  software languages and standards. The design of all our
                  products and interfaces will be driven by a user-centric
                  approach.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container padd-top-150" id="vision">
        <div className="row electrical_shocks">
          <div className="col-md-7 ">
            <AnimationOnScroll
              animateIn="animate__fadeInLeftBig"
              animateOnce={true}
              delay={1}
            >
              <h1 className="left-white-head">Our vision </h1>
              <div className="divider marg-top-20"></div>
              <p className="left-white-para marg-top-20 padd-right-50">
                RTL seeks to become the global default provider of digital
                banking services for startups and the general public. We want to
                open up the banking sector market and drive value and financial
                freedom for our customers and investors.
              </p>
            </AnimationOnScroll>
          </div>
          <div className="col-md-5">
            <img src={images.Group} height="90%" width="90%" />
          </div>
        </div>
      </div>
      <div className="container marg-top-80" id="mission">
        <div className="row">
          <div className="col-md-5">
            <img src={images.problem} height="100%" width="100%" />
          </div>
          <div className="col-md-7 padd-left-50 ">
            <AnimationOnScroll
              animateIn="animate__fadeInRightBig"
              animateOnce={true}
              delay={1}
            >
              <h1 className="left-white-head">Digital money</h1>
              <div className="divider marg-top-20"></div>
              <p className="left-white-para marg-top-20 ">
                RAG will support Bitcoin, Ethereum, DASH, NEM, and other
                emerging digital currencies to ensure it remains at the
                forefront of the cryptocurrency ecosystem.
              </p>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
      <div className="container marg-top-150" id="road-map">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <AnimationOnScroll
              animateIn="animate__fadeInLeftBig"
              animateOnce={true}
              delay={1}
            >
              <h1 className="center-white-head">Roadmap</h1>
            </AnimationOnScroll>
            <div className="divider-center marg-top-30"></div>
            <AnimationOnScroll
              animateIn="animate__fadeInRightBig"
              animateOnce={true}
              delay={1}
            >
              <p className="centrer-white-para marg-top-20">
                Our roadmap provides us with the steps that are needed to do
                good and bring wealth to our community and the world. Ragnar
                will also fund global research and social projects.
              </p>
            </AnimationOnScroll>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
      <div className="container marg-top-80">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <AnimationOnScroll
              animateIn="animate__fadeInLeftBig"
              animateOnce={true}
              delay={1}
            >
              <div className="grey-bg">
                <p className="grey-sec-para1">
                  Establishing a board of directors by December 2022
                </p>
              </div>
              <div className="grey-bg-top">
                <p className="grey-sec-para1 ">
                  {" "}
                  Pre-ICO by the end of February 2023.
                </p>
              </div>
              <div className="grey-bg-top">
                <p className="grey-sec-para1 ">
                  {" "}
                  Starting deposits and lending business by early 2026
                  (initially from RTL’s capital).
                </p>
              </div>
            </AnimationOnScroll>
          </div>
          <div className="col-md-2 snake-img-mbl">
            <img src={images.snake} alt="" height="80%" />
          </div>
          <div className="col-lg-4 col-md-4 ">
            <AnimationOnScroll
              animateIn="animate__fadeInRightBig"
              animateOnce={true}
              delay={1}
            >
              <div className="grey-bg-right">
                <p className="grey-sec-para1">
                  {" "}
                  Launch the minimum viable product (MVP) with a working IBAN
                  and payment cards support by December 2023.
                </p>
              </div>
              <div className="grey-bg-top">
                <p className="grey-sec-para1 ">
                  {" "}
                  Achieve compliance with the necessary regulatory requirements
                  by mid to end 2025.
                </p>
              </div>
              
            </AnimationOnScroll>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
      <div className="row marg-top-min-inf">
        <div className="col-md-4"></div>
        <div className="col-md-4 col-sl-12 make-center">
          
        </div>
        <div className="col-md-4"></div>
      </div>
      <div id="contact">
        <Contactus />
      </div>
      <section className="marg-top-100 ">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="/">
                  <img src={images.logo} height="35px" />
                  <span className="brand_name">Ragnar Trading limited</span>
                </a>
                <ul className="navbar-nav right-align-nav">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="#about"
                      aria-current="page"
                      to="/about"
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link " href="#goals" to="/goals">
                      Goals
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link " href="#vision" to="/vision">
                      Vision
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      href="#road-map"
                      to="/roadmap"
                      aria-disabled="true"
                    >
                      Roadmap
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      href="#contact"
                      to="/contact"
                      aria-disabled="true"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <div className="icons" style={{ marginTop: "22px" }}>
                <a
                  href="https://www.instagram.com/ragnarrtoken/"
                  target="__blank"
                >
                  <img src={images.instagram} alt="" height="25px" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100087904626280"
                  target="__blank"
                >
                  {" "}
                  <img src={images.fb} alt="" height="25px" />
                </a>
                <a href="https://twitter.com/ragnartoken" target="__blank">
                  {" "}
                  <img src={images.twitter} alt="" height="25px" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <p className="cent-white-para marg-top-50">
              Copyright © 2022 Ragnar Trading Limited
            </p>
          </div>
          <div className="col-md-4"></div>
        </div>
      </section>
    </>
  );
};
export default Body;
