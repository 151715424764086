import { useAccount } from "wagmi";

import { MintNFT } from "./Buy";

export function AppWallet(props) {
  
  const { isConnected } = useAccount();

  if (isConnected) {
    return (
      <div>
        {/* Account content goes here */}
        <MintNFT  value={props.value} />
      </div>
    );
  }

  return <div>{/* Connect wallet content goes here */}</div>;
}
