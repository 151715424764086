import React, { useState } from "react";
import "../style/Header.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { TickerTape } from "react-tradingview-embed";
import images from "../api/images";
import { Link } from "react-router-dom";
import Body from "./Body";
const Header = () => {
  const [Show, setshow] = useState(false);
  return (
    <>
      <section className="bg-hero">
        <div>
          <TickerTape widgetProps={{ theme: "dark" }} />
        </div>
        <div className="container">
          <div className="row">
            <nav className="navbar navbar-expand-lg navbar-light">
              <a className="navbar-br[pand" href="/">
                <img src={images.logo} height="35px" />
                <span className="brand_name">Ragnar Trading limited</span>
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setshow(!Show)}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse ${Show ? "show" : "hide"}`}
                id="navbarNav"
              >
                <ul className="navbar-nav right-align-nav">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="#about"
                      aria-current="page"
                      to="/about"
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link " href="#goals" to="/goals">
                      Goals
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link " href="#vision" to="/vision">
                      Vision
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      href="#road-map"
                      to="/roadmap"
                      aria-disabled="true"
                    >
                      Roadmap
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href={images.whitepaper}
                      target="__blank"
                      className="nav-link "
                    >
                      Download /View whitepaper
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link "
                      href="#contact"
                      to="/contact"
                      aria-disabled="true"
                    >
                      Contact us
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link
                      href="/buytoken"
                      className="nav-link"
                      to="/buytoken"
                      aria-disabled="true"
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        backgroundColor: "#2DCFCB",
                        border: "none",
                        borderRadius: "5px",
                        padding: "7px",
                        marginTop: "-5px",
                      }}
                    >
                      Buy Tokens
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>

        {/* Header */}

        <div className="container mt-5">
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-md-6">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
                delay={1}
              >
                <h1 className="center-white-head-height">
                  Research &<br></br>Cause-based<br></br>
                </h1>
                <p className="centrer-white-para-left">
                  Startup organisations and the general public require access to
                  banking infrastructure at a low cost without compromising on
                  service quality. The traditional banking sector does not cater
                  for this need and so there is a real opportunity to develop
                  this market sector. This gap could be addressed via a digital
                  banking solution that supports startups but also partners with
                  them throughout their growth journey. Ragnar Trading Limited
                  (RTL) is our proposed solution.
                </p>
                <div className="icons">
                  <a
                    href="https://www.instagram.com/ragnarrtoken/"
                    target="__blank"
                  >
                    <img src={images.instagram} alt="" height="25px" />
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=100087904626280"
                    target="__blank"
                  >
                    {" "}
                    <img src={images.fb} alt="" height="25px" />
                  </a>
                  <a href="https://twitter.com/ragnartoken" target="__blank">
                    {" "}
                    <img src={images.twitter} alt="" height="25px" />
                  </a>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-6">
              <img src={images.header} width="90%"></img>
            </div>
          </div>
        </div>
      </section>
      <Body></Body>
    </>
  );
};
export default Header;
