import Header from "./layout/Header";
import { useEffect } from "react";
import Footer from "./layout/Footer";
import Swap from "./layout/Swap";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-250310673-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {
  useEffect(() => {
    window.snaptr = function () {
      var a = arguments;
      a.loaded = true;
      window.snaptr.dispatch
        ? window.snaptr.dispatch.apply(window.snaptr, a)
        : window.snaptr.queue.push(a);
    };
    window.snaptr.queue = [];
    window.snaptr("init", "9f1b1fc2-c973-47c2-8df2-6a7720bdaa8f", {});
  }, []);
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Header />}></Route>
          <Route path="/" element={<Footer />}></Route>
          <Route path="/buytoken" element={<Swap />}></Route>
        </Routes>
      </Router>
      <Footer />
    </>
  );
}

export default App;
