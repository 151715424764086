import React from "react";
import "../style/Footer.css";
import images from "../api/images";
const Footer = () => {
  return (
    <>
     
    </>
  );
};
export default Footer;
