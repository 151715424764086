import logo from "../assets/images/logo.png";
import phasphorus1 from "../assets/images/phasphorus1.png";
import phasvid from "../assets/images/phasvid.png";
import vidicon from "../assets/images/vidicon.png";
import ph2 from "../assets/images/ph2.png";
import ph3 from "../assets/images/ph3.png";
import snake from "../assets/images/snake.png";
import firstcol from "../assets/images/firstcol.png";
import secondben from "../assets/images/secondben.png";
import benifitstri from "../assets/images/benifitstri.png";
import thirdben from "../assets/images/thirdben.png";
import fourthben from "../assets/images/fourthben.png";
import fifthben from "../assets/images/fifthben.png";
import crypto from "../assets/images/crypto.png";
import fb from "../assets/images/Facebook.png";
import linkdin from "../assets/images/linkdin.png";
import instagram from "../assets/images/instagram.png";
import search from "../assets/images/search.png";
import twitter from "../assets/images/twitter.png";
import sixthben from "../assets/images/sixthben.png";
import video from "../assets/images/video.png";
import playicon from "../assets/images/playicon.png";
import blockchain from "../assets/images/blockchain.png";
import token from "../assets/images/token.png";
import Group from "../assets/images/Group 1000001374.png";
import waves from "../assets/images/waves.png";
import problem from "../assets/images/problem.png";
import GroupR from "../assets/images/GroupR.png";
import header from "../assets/images/header.png";
import whitepaper from "../assets/images/whitepaper 2.pdf";
import binance_logo from "../assets/images/binance_logo.png";
import rag from "../assets/images/hemp.png";
const allimages = {
  rag: rag,
  binance_logo: binance_logo,
  whitepaper: whitepaper,
  sixthben: sixthben,
  logo: logo,
  phasphorus1: phasphorus1,
  phasvid: phasvid,
  vidicon: vidicon,
  ph2: ph2,
  ph3: ph3,
  snake: snake,
  firstcol: firstcol,
  secondben: secondben,
  benifitstri: benifitstri,
  thirdben: thirdben,
  fourthben: fourthben,
  fifthben: fifthben,
  crypto: crypto,
  fb: fb,
  twitter: twitter,
  linkdin: linkdin,
  instagram: instagram,
  search: search,
  video: video,
  playicon: playicon,
  blockchain: blockchain,
  token: token,
  Group: Group,
  waves: waves,
  problem: problem,
  GroupR: GroupR,
  header: header,
};

export default allimages;
