import {
  useAccount,
  useConnect,
  useEnsName,
} from "wagmi";
import {Container , Alert} from 'react-bootstrap' 
export function Profile() {
  const { address,  isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { connect, error } =
    useConnect();


  if (isConnected) {
    return (
      <div className="address">
        <Container  style={{margin: "22px 17px 10px -13px"}}>  
        <div><Alert variant="success" style={{fontSize:"14px"}}>{ensName ? `${ensName} (${address})` : address} <br></br>Connected!</Alert></div>
       
        </Container>
      </div>
    );
  }
  return <div>{error && <div>{error.message}</div>}</div>;
}
