import * as React from "react";
import { useDebounce } from "use-debounce";
import 'bootstrap/dist/css/bootstrap.min.css';  
import {Container , Alert} from 'react-bootstrap'  
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { useAccount } from "wagmi";
import { utils } from 'ethers'

export function MintNFT({value}) {
  const { address, connector, isConnected } = useAccount();
  
  const [to, setTo] = React.useState(address);
  const [debouncedTo] = useDebounce(to, 500);
  
  
  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    //Mainnet = 0xac8e18e1a9a141b2a636736a5775b460f4e9d98d
    //TestNet = 0xf9d861e8032dd4bf0fc9761290505eba25e6c7c3
    address: "0xD6A0f685fAdC6f7396bde10f79433250699788Da",
    abi: [
      { payable: true, stateMutability: "payable", type: "fallback" },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "beneficiary", type: "address" },
        ],
        name: "buyTokens",
        outputs: [],
        payable: true,
        stateMutability: "payable",
        type: "function",
      },
    ],
    functionName: "buyTokens",
    args: [debouncedTo],
    overrides: {
      from: address, // Current Connected Wallet -> Profile Comp
      value: utils.parseEther(value.toString()), // BNB Input Value -> Swap
    },
  });

  console.log(utils.parseEther(value.toString()));
  const { data, error, isError, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  return (
    <div>
      <button style={{ fontWeight: "400", fontSize: "16px", color:"black" ,backgroundColor:"#2DCFCB",border:"none" ,borderRadius:"5px" ,padding:"9px 35px 9px 35px" ,marginTop:"-5px" }}
        disabled={!write || isLoading}
        onClick={() => write() }
      >
        {isLoading ? "Buying..." : "Buy"}
      </button>
      {isSuccess && (
        <div>
          Successfully Buy RAG!
          <div>
            <a href={`https://bscscan.io/tx/${data?.hash}`}>BscScan</a>
          </div>
        </div>
      )}
       <Container  style={{margin: "22px 17px 10px -13px"}}>  
      {(isPrepareError || isError) && (
        <div><Alert variant="danger" style={{fontSize:"14px"}}>Error: {(prepareError || error)?.message}</Alert></div>
      )}
      </Container>
    </div>
  );
}
