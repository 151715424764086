import React, { useState } from "react";
import "../style/Header.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import images from "../api/images";
import "../style/swap.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { ethers } from "ethers";
import Web3 from "web3";
import { Web3Button } from "@web3modal/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { Profile } from "./Profile";
import { AppWallet } from "./walletCon";

const Swap = () => {
  const [Show, setshow] = useState(false);
  const [rag, setRag] = useState(1.0);
  const [bnb, setBnb] = useState(0.13);
  const [price, setPrice] = useState(0.13);
  const [SwapBtnText, setSwapBtnText] = useState("Change Your Network to BSC!");
  const [errRes, setErrRes] = useState("");
  const [currentAccount, setCurrentAccount] = useState(null);

  const bsc = {
    id: 56,
    name: "Binance Smart Chain",
    network: "BSC",
    nativeCurrency: {
      decimals: 18,
      name: "Binance",
      symbol: "BNB",
    },
    rpcUrls: {
      default: "https://bsc-dataseed.binance.org/",
    },
    blockExplorers: {
      default: { name: "BscScan", url: "https://bscscan.com" },
    },
    testnet: false,
  };
  // mainnet "bsc" , Test = chain.goerli
  const chains = [bsc];

  // Wagmi client
  const { provider } = configureChains(chains, [
    walletConnectProvider({ projectId: "5f5532aa23854f6175968998c6ea3474" }),
  ]);
  const wagmiClient = createClient({
    autoConnect: true,
    connectors: modalConnectors({ appName: "web3Modal", chains }),
    provider,
  });

  // Web3Modal Ethereum Client
  const ethereumClient = new EthereumClient(wagmiClient, chains);
  const ragA = {
    address: "0xD6A0f685fAdC6f7396bde10f79433250699788Da",
    abi: [
      {
        inputs: [
          { internalType: "uint256", name: "rate", type: "uint256" },
          { internalType: "address payable", name: "wallet", type: "address" },
          { internalType: "contract IERC20", name: "token", type: "address" },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "purchaser",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "beneficiary",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "TokensPurchased",
        type: "event",
      },
      { payable: true, stateMutability: "payable", type: "fallback" },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "beneficiary", type: "address" },
        ],
        name: "buyTokens",
        outputs: [],
        payable: true,
        stateMutability: "payable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "rate",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "token",
        outputs: [
          { internalType: "contract IERC20", name: "", type: "address" },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "wallet",
        outputs: [
          { internalType: "address payable", name: "", type: "address" },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "weiRaised",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ],
  };

  const [accounts, setAccounts] = useState([]);
  const [connectWalletText, setConnectWalletText] = useState("Connect Wallet");

  const provider_o =
    window.ethereum != null
      ? new ethers.providers.Web3Provider(window.ethereum)
      : ethers.providers.getDefaultProvider();

  async function getAccount() {
    let acc = await window.ethereum.request({ method: "eth_requestAccounts" });
    setAccounts(acc);
    setConnectWalletText(acc.toString().substring(0, 12) + "...");
  }

  async function getBalance() {
    let web3 = new Web3(window.ethereum);
    let balance = web3.eth.getBalance(accounts.toString());
    console.log(balance);
  }

  async function getChainId() {
    const chainID = await window.ethereum.request({ method: "eth_chainId" });
    handleChainChanged(chainID);
  }

  function handleAccountsChanged(accountss) {
    if (accountss.length === 0) {
      // MetaMask is locked or the user has not connected any accountss
      console.log("Please connect to MetaMask.");
      window.alert("Please connect to MetaMask.");
      setConnectWalletText("Connect to MetaMask");
    } else if (accountss[0] !== currentAccount) {
      setCurrentAccount(accountss[0]);
      setConnectWalletText(currentAccount.toString().substring(0, 12) + "...");
    }
  }

  function handleChainChanged(_chainId) {
    if (parseInt(_chainId, 16) !== 56) {
      window.alert("Change Your Network to Binance Smart Chain!");
      setSwapBtnText("Change Your Network to BSC!");
      // buy.disabled = true;
    } else {
      setSwapBtnText("Buy Token Now");
      // buy.disabled = false;
    }
  }

  async function addBscNetwork() {
    window.ethereum
      .request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x38",
            chainName: "Binance Smart Chain",
            nativeCurrency: {
              name: "Binance Coin",
              symbol: "BNB",
              decimals: 18,
            },
            rpcUrls: ["https://bsc-dataseed.binance.org/"],
            blockExplorerUrls: ["https://bscscan.com"],
          },
        ],
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function ConnectWallet() {
    getAccount();
    getBalance();
  }

  function changeBNB(event) {
    if (event.target.value < 0) {
      setRag(1);
      setBnb(1 * price);
    } else {
      setRag(event.target.value);
      setBnb(event.target.value * price);
    }
  }

  async function transferHemp() {
    let amount = bnb;
    amount = amount.toString();
    let receiver = ragA.address;
    let response;

    getAccount();
    const signer = provider_o.getSigner();

    const hempContract = new ethers.Contract(ragA.address, ragA.abi, signer);

    response = `Transferring ${amount} BNB receiver ${receiver.slice(
      0,
      12
    )}...`;
    setErrRes(response);

    let overrides = {
      // The amount to send with the transaction (i.e. msg.value)
      value: ethers.utils.parseEther(amount),
    };
    const senderAddress = accounts.toString();
    let tx = hempContract.buyTokens(senderAddress, overrides);
    tx.then((result) => {
      console.log("tx: ", result);
      setErrRes(`Transaction hash: ${result.hash}`);
    }).catch((error) => {
      window.alert(error.message);
      console.log("tx: ", error);
    });
  }

  function buyRag() {
    if (bnb !== 0) {
      transferHemp();
    } else {
      window.alert("Enter Correct Value of BNB");
    }
  }

  return (
    <div>
      <section className="bg-hero">
        <div className="container" id="hero">
          <div className="row" style={{ padding: "28px 19px 7px 14px" }}>
            <div className="col-lg-8">
              <div className="logo">
                <a className="" href="/">
                  <img src={images.logo} height="35px" />
                  <span className="brand_name">Ragnar Trading limited</span>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="connectWallet">
                <WagmiConfig client={wagmiClient}>
                  <Web3Button />
                  <Profile />
                </WagmiConfig>

                <Web3Modal
                  projectId="5f5532aa23854f6175968998c6ea3474"
                  theme="dark"
                  accentColor="default"
                  ethereumClient={ethereumClient}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Header */}

        <div className="container mt-5">
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-md-6">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
                delay={1}
              >
                <h1 className="center-white-head-height">
                  Research &<br></br>Cause-based<br></br>
                </h1>
                <p className="centrer-white-para-left">
                  Startup organisations and the general public require access to
                  banking infrastructure at a low cost without compromising on
                  service quality. The traditional banking sector does not cater
                  for this need and so there is a real opportunity to develop
                  this market sector. This gap could be addressed via a digital
                  banking solution that supports startups but also partners with
                  them throughout their growth journey. Ragnar Trading Limited
                  (RTL) is our proposed solution.
                </p>
                <button
                  type="submit"
                  onClick={addBscNetwork}
                  className="form-button"
                  style={{
                    width: "50%",
                    fontWeight: "400",
                    fontSize: "16px",
                    borderRadius: "10px",
                  }}
                >
                  RAG Contract
                </button>
              </AnimationOnScroll>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5 selectValue">
              <h4 className="title title-md" style={{ color: "#2DCFCB" }}>
                RAG Token Sale
              </h4>
              <p style={{ color: "white" }}>1 RAG = 0.13 BNB</p>
              <p style={{ color: "white" }}>From</p>
              <InputGroup className="mb-3" style={{ width: "80%" }}>
                <InputGroup.Text id="basic-addon1">
                  RAG <img src={images.logo} width="25" />
                </InputGroup.Text>
                <Form.Control
                  onChange={changeBNB}
                  placeholder="0.000"
                  aria-label="0.000"
                  aria-describedby="basic-addon1"
                  type="number"
                  value={rag}
                />
              </InputGroup>
              <p style={{ color: "white" }}>To</p>

              <InputGroup className="mb-3" style={{ width: "80%" }}>
                <InputGroup.Text id="basic-addon1">
                  BNB <img src={images.binance_logo} width="25" />
                </InputGroup.Text>

                <Form.Control
                  placeholder="0.00"
                  aria-label="0.00"
                  aria-describedby="basic-addon1"
                  type="number"
                  value={bnb}
                  disabled
                />
              </InputGroup>
              <WagmiConfig client={wagmiClient}>
                <AppWallet value={bnb} />
              </WagmiConfig>
            </div>
            <p>{errRes}</p>
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: "#2DCFCB", padding: "50px" }}>
        <div className="container">
          <div className="col-md-7">
            <AnimationOnScroll
              animateIn="animate__fadeInLeftBig"
              animateOnce={true}
              delay={1}
            >
              <p className="grey-sec-head mt-5">How to Buy RAG</p>
              <ul>
                <li className="left-white-para1 marg-top-20">
                  Create a Metamask Wallet using either desktop computer or
                  mobile device. https://metamask.io/.Please ensure you have
                  Binance Smart Chain enable on your metamask wallet. If not,
                  see youtube video on How to add BSC Network on your metamask
                  <a
                    href="https://www.youtube.com/watch?v=3wQp-B2h1fg"
                    target="__blank"
                  >
                    {" "}
                    https://www.youtube.com/watch?v=3wQp-B2h1fg
                  </a>
                </li>
                <li className="left-white-para1 marg-top-20">
                  Send BNB to your wallet. You can buy BNB on MetaMask directly,
                  or through sites like Coinbase or Binance and then send them
                  to your wallet.
                </li>
                <li className="left-white-para1 marg-top-20">
                  Connect your wallet to Ragnar token's buy tab and buy RAG,
                  Please check you are sending BNB to the correct Ragnar
                  Crowdsale Address:<br></br>
                 <span className="br"> 0xD6A0f685fAdC6f7396bde10f79433250699788Da</span>
                  <p class="claim_text">
                    To connect your wallet scroll up.
                    <a href="#hero">
                      <FontAwesomeIcon
                        style={{ marginLeft: "10px", color: "black" }}
                        className="trial-icon"
                        icon={faArrowUp}
                      ></FontAwesomeIcon>
                    </a>
                  </p>
                </li>
                <li className="left-white-para1 marg-top-20">
                  In order to view the RAG in your wallet, once you have
                  purchased RAG, go to your wallet, ensure it is on the BSC
                  (Binance Smart Chain Main Net) network, go to import tokens,
                  click on import custom tokens, enter the following token
                  address:<br></br>
                  <span className="br"> 0x0099Cfd4458eEDf813cE359cbdeC6D7bCa2e18ED</span><br></br> and click
                  on import tokens. You should now see the RAG you purchased in
                  your wallet
                </li>
              </ul>
            </AnimationOnScroll>
          </div>
        </div>
      </section>
      <section className="marg-top-100 ">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="/">
                  <img src={images.logo} height="35px" />
                  <span className="brand_name">Ragnar Trading limited</span>
                </a>
              </nav>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <div className="icons" style={{ marginTop: "22px" }}>
                <a
                  href="https://www.instagram.com/ragnarrtoken/"
                  target="__blank"
                >
                  <img src={images.instagram} alt="" height="25px" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100087904626280"
                  target="__blank"
                >
                  {" "}
                  <img src={images.fb} alt="" height="25px" />
                </a>
                <a href="https://twitter.com/ragnartoken" target="__blank">
                  {" "}
                  <img src={images.twitter} alt="" height="25px" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <p className="cent-white-para marg-top-50">
              Copyright © 2022 Ragnar Trading Limited
            </p>
          </div>
          <div className="col-md-4"></div>
        </div>
      </section>
    </div>
  );
};

export default Swap;
