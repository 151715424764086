import React, { useState } from "react";
import "../style/form.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import instance from "./url";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Contactus = () => {
  const [User, setUser] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const notify =(msg)=>{
    toast(`${msg}`)
  };
  const [validated, setValidated] = useState(false);
  const handleChange = (e) => {
    setUser({
      ...User,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (User.name !== "" && User.phone !== "" && User.email !== "") {
      instance.post("/Contact", User).then((response) => {
        // setmsg(response.data);
        notify(response.data);
      });
    }
  };
  return (
    <div>
      <section className="form-blue-bg">
      
        
        <Container>
          <Row>
            <Col md={4} className="mt-5">
              <p className="from-black-head pt-5 mt-4">
                Contact us for more info
              </p>
              
              <p className="form-email-left mt-5" style={{color: "black"}}>
                Mail
              </p>
              <p className="form-email-left-para">
                info@ragnartoken.com
              </p>
            </Col>
            <Col md={2}></Col>
            <Col md={6} className="form-dic-padding">
              <div className="form-bg">
                <p className="get-in-touch">Get in touch with us</p>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group className="mt-3" controlId="validationCustom03">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      required
                      onChange={handleChange}
                      value={User.name}
                      className="input"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a Name.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mt-3" controlId="validationCustom03">
                    <Form.Control
                      type="phone"
                      placeholder="Phone"
                      name="phone"
                      required
                      onChange={handleChange}
                      value={User.phone}
                      className="input"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a Phone Number.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mt-3" controlId="validationCustom03">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      required
                      onChange={handleChange}
                      value={User.email}
                      className="input"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a Email.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Form.Control
                      type="textarea"
                      className="input"
                      name="message"
                      placeholder="Message"
                      onChange={handleChange}
                      value={User.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid city.
                    </Form.Control.Feedback>
                  </Form.Group>
                 
                  <Button type="submit" className="form-button">
                    Submit
                  </Button>
                  {/* <span className="msg">{msg ? notify(msg):""}</span> */}
                  <ToastContainer />
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Contactus;
